/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AbilityName {
  admin = "admin",
  advancement_pdf = "advancement_pdf",
  area_manager = "area_manager",
  fraction_images = "fraction_images",
  housing_management = "housing_management",
  manager = "manager",
  town_boundaries = "town_boundaries",
  tugraz = "tugraz",
  waste_composition = "waste_composition",
}

export enum AdvancementPDFStatus {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum ChangePasswordError {
  NOT_ENOUGH_DIFFERENCT_CHARACTERS = "NOT_ENOUGH_DIFFERENCT_CHARACTERS",
  SAME_PASSWORD = "SAME_PASSWORD",
  TOO_SHORT = "TOO_SHORT",
}

export enum ChannelType {
  black_board = "black_board",
  daheim_app = "daheim_app",
  email = "email",
  newspaper = "newspaper",
  sms = "sms",
  symvaro = "symvaro",
}

export enum CitizenCommunicationChannel {
  email = "email",
  phone = "phone",
}

export enum ClassificationSelector {
  ALL = "ALL",
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
  STANDARD = "STANDARD",
}

export enum CommunicationChannelType {
  BLACK_BOARD = "BLACK_BOARD",
  DAHEIM_APP = "DAHEIM_APP",
  EMAIL = "EMAIL",
  NEWSPAPER = "NEWSPAPER",
  SMS = "SMS",
  SYMVARO = "SYMVARO",
}

export enum DocumentType {
  advancement_pdf = "advancement_pdf",
  advancement_pdf_zip = "advancement_pdf_zip",
  label_image = "label_image",
  overlay_image = "overlay_image",
  overlay_image_low_res = "overlay_image_low_res",
  overview_image = "overview_image",
  trash_image = "trash_image",
  trash_image_low_res = "trash_image_low_res",
}

export enum ErrorTypes {
  ASSOCIATION_NOT_FOUND = "ASSOCIATION_NOT_FOUND",
  BOOLEAN_VALUE_INCORRECT = "BOOLEAN_VALUE_INCORRECT",
  DUPLICATE = "DUPLICATE",
  EMPTY_ROW = "EMPTY_ROW",
  ENDTIME_BEFORE_STARTTIME = "ENDTIME_BEFORE_STARTTIME",
  ENTRY_INCOMPLETE = "ENTRY_INCOMPLETE",
  FRACTION_NOT_FOUND = "FRACTION_NOT_FOUND",
  HEADER_INCORRECT = "HEADER_INCORRECT",
  NO_ACCESS_TO_VEHICLE = "NO_ACCESS_TO_VEHICLE",
  NO_ROWS_FOUND = "NO_ROWS_FOUND",
  PREFERRED_COMMUNICATION_CHANNEL_VALUE_INCORRECT = "PREFERRED_COMMUNICATION_CHANNEL_VALUE_INCORRECT",
  RFID_NOT_FOUND = "RFID_NOT_FOUND",
  RFID_NOT_MATCHING_TOWN = "RFID_NOT_MATCHING_TOWN",
  TABLE_NOT_FOUND = "TABLE_NOT_FOUND",
  TIME_FORMAT_INCORRECT = "TIME_FORMAT_INCORRECT",
  TIME_SPAN_INCORRECT = "TIME_SPAN_INCORRECT",
  TOWN_NOT_FOUND = "TOWN_NOT_FOUND",
  WASTESCANNER_NOT_FOUND = "WASTESCANNER_NOT_FOUND",
}

export enum ExportRfidGroupsError {
  NO_RFID_GROUPS_FOR_TOWN = "NO_RFID_GROUPS_FOR_TOWN",
}

export enum ExternalTownType {
  daheim = "daheim",
  symvaro = "symvaro",
}

export enum Frequency {
  daily = "daily",
  monthly = "monthly",
  weekly = "weekly",
}

export enum InvalidityType {
  BAG_AMOUNT = "BAG_AMOUNT",
  BRIGHTNESS = "BRIGHTNESS",
  BY_USER = "BY_USER",
  CURTAIN = "CURTAIN",
  DUST = "DUST",
  GENERAL = "GENERAL",
  NOT_INVALID = "NOT_INVALID",
  OTHER = "OTHER",
  PRESS = "PRESS",
  SPLIT_NOT_VALIDATED = "SPLIT_NOT_VALIDATED",
}

export enum LabelSetMaterialType {
  bags = "bags",
  ignore = "ignore",
  main_fraction = "main_fraction",
  missthrow = "missthrow",
}

export enum LogLevel {
  error = "error",
  log = "log",
}

export enum LogType {
  black_board = "black_board",
  daheim_app = "daheim_app",
  email = "email",
  newspaper = "newspaper",
  sms = "sms",
  symvaro = "symvaro",
}

export enum MappedClassification {
  AUTOMATICALLY = "AUTOMATICALLY",
  MANUALLY = "MANUALLY",
  ORIGINAL = "ORIGINAL",
}

export enum MaterialAnalysisSource {
  geocept = "geocept",
  other = "other",
  rfid = "rfid",
}

export enum Rating {
  High = "High",
  Low = "Low",
  Medium = "Medium",
  Unknown = "Unknown",
}

export enum RfidChipType {
  SA1100L = "SA1100L",
  SA120L = "SA120L",
  SA240L = "SA240L",
  SA360L = "SA360L",
  SA770L = "SA770L",
  SA80L = "SA80L",
  Sonstiges = "Sonstiges",
  T1100 = "T1100",
  T120 = "T120",
  T240 = "T240",
  T360 = "T360",
  T60 = "T60",
  T660 = "T660",
  T770 = "T770",
  T80 = "T80",
}

export enum TextPlaceholderKey {
  INVALID_REASON = "INVALID_REASON",
  INVALID_TOTAL_COUNT = "INVALID_TOTAL_COUNT",
  MAIN_MISSTHROW_1_NAME = "MAIN_MISSTHROW_1_NAME",
  MAIN_MISSTHROW_2_NAME = "MAIN_MISSTHROW_2_NAME",
  MATERIAL_ANALYSIS_LIST = "MATERIAL_ANALYSIS_LIST",
  MISSTHROW_PERCENTAGE = "MISSTHROW_PERCENTAGE",
  MISSTHROW_PER_TOWN_PERCENTAGE = "MISSTHROW_PER_TOWN_PERCENTAGE",
  NEW_LINE_DOUBLE = "NEW_LINE_DOUBLE",
  NEW_LINE_SINGLE = "NEW_LINE_SINGLE",
  RFID = "RFID",
  TOUR_TYPE = "TOUR_TYPE",
  TOWN_NAME = "TOWN_NAME",
}

export enum TextplaceHolderTypeKey {
  INTEGER = "INTEGER",
  NAME = "NAME",
  PERCENTAGE = "PERCENTAGE",
  QUOTED = "QUOTED",
}

export enum TourType {
  organic = "organic",
  residual = "residual",
}

export enum WER_ERROR_CODE {
  ABILITY_MISSING = "ABILITY_MISSING",
  DUPLICATE = "DUPLICATE",
  NOT_FOUND = "NOT_FOUND",
  OTHER = "OTHER",
  SIGN_IN__EMAIL_UNKNOWN = "SIGN_IN__EMAIL_UNKNOWN",
  SIGN_IN__WRONG_PASSWORD = "SIGN_IN__WRONG_PASSWORD",
  SUCCESS = "SUCCESS",
  USER__EMAIL_EXISTS = "USER__EMAIL_EXISTS",
  USER__INVALID_INPUT = "USER__INVALID_INPUT",
}

export enum WarningTypes {
  CODE_FOR_RFID_UPDATED = "CODE_FOR_RFID_UPDATED",
  DUPLICATE = "DUPLICATE",
  OVERLAPPING_ENTRY = "OVERLAPPING_ENTRY",
  OVERLAPPING_ENTRY_DATE = "OVERLAPPING_ENTRY_DATE",
  OVERLAPPING_ENTRY_DATE_TIME = "OVERLAPPING_ENTRY_DATE_TIME",
  RFID_ALREADY_EXISTS = "RFID_ALREADY_EXISTS",
  RFID_FOUND_IN_OTHER_TOWN = "RFID_FOUND_IN_OTHER_TOWN",
  RFID_IMPORTED_IN_OTHER_TOWN = "RFID_IMPORTED_IN_OTHER_TOWN",
  RFID_NOT_FOUND = "RFID_NOT_FOUND",
  UNKNOWN_RFID_CHIP_TYPE = "UNKNOWN_RFID_CHIP_TYPE",
}

export enum WidgetType {
  chart_history = "chart_history",
  chart_pie = "chart_pie",
  chart_pie_waste_quality = "chart_pie_waste_quality",
  chart_trend = "chart_trend",
  chart_trend_comparison = "chart_trend_comparison",
  text = "text",
  text_individual = "text_individual",
}

export interface AddressInput {
  street: string;
  streetNumber: string;
  postalcode: string;
  city: string;
}

export interface AssociationInput {
  name: string;
  documentDeletionRangeInDays?: number | null;
  location: LocationInput;
  radius: number;
  defaultClassifiers?: ClassifierForTourTypeInput[] | null;
  fractionMapping?: FractionMappingInput[] | null;
}

export interface AssociationInputUpdate {
  name?: string | null;
  documentDeletionRangeInDays?: number | null;
  location?: LocationInput | null;
  radius?: number | null;
  ratingSchema?: RatingSchemaInput | null;
  defaultClassifiers?: ClassifierForTourTypeInput[] | null;
  fractionMapping?: FractionMappingInput[] | null;
}

export interface CitizenInput {
  firstName?: string | null;
  lastName?: string | null;
  rfid: string;
  address?: string | null;
  detailedAddress?: DetailedAddressInput | null;
  phones?: (string | null)[] | null;
  email?: string | null;
  deviceId?: string | null;
  townId?: string | null;
  externalTownType?: ExternalTownType | null;
  preferredCommunicationChannels?: (CitizenCommunicationChannel | null)[] | null;
}

export interface ClassifierForTourTypeInput {
  tourType: TourType;
  classifierId: string;
}

export interface DetailedAddressInput {
  street?: string | null;
  streetNumber?: string | null;
  postalcode?: string | null;
  city?: string | null;
  formattedAddress?: string | null;
}

export interface ExternalTownInput {
  externalId: string;
  type: ExternalTownType;
}

export interface FractionFilter {
  fraction: string;
  range: Range;
}

export interface FractionMappingInput {
  tourType: TourType;
  mappedFractions: MappedFractionInput[];
}

export interface FractionValueInput {
  fraction: string;
  value: number;
}

export interface LocationInput {
  latitude: number;
  longitude: number;
}

export interface MappedFractionInput {
  fraction: string;
  mappedMaterialType: LabelSetMaterialType;
}

export interface MaterialAnalysisInvalidityInput {
  isInvalid: boolean;
  invalidReason: string;
  invalidType: InvalidityType;
}

export interface MinMaxInput {
  min: number;
  max: number;
}

export interface PermittedAssociationInput {
  associationId: string;
  accessToAllTowns: boolean;
  towns: string[];
}

export interface PersistedFilterInput {
  name?: string | null;
  userId?: string | null;
  associationId?: string | null;
  townIds?: string[] | null;
  emptyingWeekNumber?: number | null;
  emptyingYearNumber?: number | null;
  rating?: Rating | null;
  fractionType?: string | null;
  fractionFrom?: number | null;
  fractionTo?: number | null;
  dateFrom?: any | null;
  dateUntil?: any | null;
  displayOnlyAutomaticAnalysis?: boolean | null;
  tourType?: TourType | null;
  source?: MaterialAnalysisSource | null;
  isBookmark?: boolean | null;
  widgetComparisonTownIds?: string[] | null;
  rfids?: string[] | null;
}

export interface Range {
  start?: number | null;
  end?: number | null;
}

export interface RatingSchemaInput {
  residual: RatingSchemaPercentageInput;
  organic: RatingSchemaPercentageInput;
}

export interface RatingSchemaPercentageInput {
  percentageFrom: number;
  percentageTo: number;
}

export interface StatisticsFilter {
  associationId?: string | null;
  townIds?: string[] | null;
  wasteScannerId?: string | null;
  timeRange?: TimeRangeInput | null;
  classification?: ClassificationSelector | null;
}

export interface TimeRangeInput {
  start?: any | null;
  end?: any | null;
}

export interface TourFilter {
  associationId?: string | null;
  townIds: string[];
  tourType?: TourType | null;
  wasteScannerId?: string | null;
  timeRange?: TimeRangeInput | null;
  hasAnalysis?: boolean | null;
  hasRfidReader?: boolean | null;
}

export interface TourInput {
  townIds: string[];
  tourStart: any;
  tourEnd: any;
  tourType: TourType;
  vehicleId: string;
  rfidReader?: string | null;
  geoCeptImei?: string | null;
  includesSplittedImages: boolean;
  mappedClassification: MappedClassification;
}

export interface TownInput {
  name: string;
  zipCode?: number | null;
  location: LocationInput;
  radius: number;
  associationId: string;
  externalTown?: ExternalTownInput | null;
  townBoundaryId?: string | null;
  displayOnlyAutomaticAnalysis: boolean;
  defaultClassifiers?: ClassifierForTourTypeInput[] | null;
  documentDeletionRangeInDays?: number | null;
  fractionMapping?: FractionMappingInput[] | null;
}

export interface TownInputUpdate {
  name: string;
  zipCode?: number | null;
  location: LocationInput;
  radius: number;
  documentDeletionRangeInDays?: number | null;
  associationId: string;
  externalTown?: ExternalTownInput | null;
  townBoundaryId?: string | null;
  displayOnlyAutomaticAnalysis?: boolean | null;
  ratingSchema?: RatingSchemaInput | null;
  fractionMapping?: FractionMappingInput[] | null;
  defaultClassifiers?: ClassifierForTourTypeInput[] | null;
}

export interface UserAndPermissionsInput {
  user: UserInput;
  permittedAssociations: PermittedAssociationInput[];
}

export interface UserAndPermissionsInputUpdate {
  user: UserInputUpdate;
  permittedAssociations: PermittedAssociationInput[];
}

export interface UserInput {
  email: string;
  name?: string | null;
  defaultTownId?: string | null;
  abilities: AbilityName[];
  vehicleIds: string[];
  housingManagementIds: string[];
}

export interface UserInputUpdate {
  id: string;
  name?: string | null;
  defaultTownId?: string | null;
  abilities: AbilityName[];
  vehicleIds: string[];
  housingManagementIds: string[];
}

export interface ValueForRatingInput {
  rating: Rating;
  value?: string | null;
}

export interface WidgetTextTemplateInput {
  title: string;
  rating: Rating;
  textTemplate: string;
  widgetTextCategoryId: string;
  associationId?: string | null;
  townId?: string | null;
}

export interface WidgetTextTemplateInputUpdate {
  title?: string | null;
  textTemplate?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
